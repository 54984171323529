import React from 'react';
import { CssBaseline, Drawer, List, ListItem, ListItemText, AppBar, Toolbar, Typography, Box, Button } from '@mui/material';
import { styled } from '@mui/system';
import { Link, useLocation } from 'react-router-dom';  // Importa o useLocation
import logo from '../assets/icons/logo_ebix_branco.png';

const drawerWidth = 270;

const Root = styled('div')({
  display: 'flex',
});

const AppBarStyled = styled(AppBar)({
  zIndex: 1201, // Ensure the AppBar is above the Drawer
  backgroundColor: '#0095da', // Define a cor de fundo do topo
});

const DrawerStyled = styled(Drawer)({
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
  },
});

const Content = styled('main')(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(1), // Ajuste o padding se necessário
  width: '100%', // Certifique-se de que ocupa toda a largura disponível
}));

const ToolbarSpacer = styled('div')(({ theme }) => ({
  ...theme.mixins.toolbar,
}));

const Layout = ({ children, handleLogin, handleLogout, isAuthenticated }) => {
  const location = useLocation();  // Obtém a localização atual

  return (
    <Root>
      <CssBaseline />
      <AppBarStyled position="fixed">
        <Toolbar>
          <Box display="flex" alignItems="center" width="100%">
            <img src={logo} alt="Alice Logo" style={{ width: '50px', marginRight: '16px' }} />
            <Typography variant="h6" noWrap style={{ flexGrow: 1 }}>
              A L I C E - Assistente de Linguagem Inteligente e Código da Ebix
            </Typography>
            {isAuthenticated ? (
              <Button color="inherit" onClick={handleLogout}>Logout</Button>
            ) : (
              <Button color="inherit" onClick={handleLogin}>Login</Button>
            )}
          </Box>
        </Toolbar>
      </AppBarStyled>
      <DrawerStyled variant="permanent">
        <ToolbarSpacer />
        <List>
          {[
            { text: 'Assistente de Código', path: '/alice-codigo' },
            { text: 'Assistente de Vulnerabilidades', path: '/alice-vulnerabilidades' },
            { text: 'Assistente de Requisitos', path: '/alice-requisitos' },
            { text: 'Assistente de CSP - COBOL', path: '/alice-cobol' }
          ].map((item) => (
            <ListItem
              button
              key={item.text}
              component={Link}
              to={item.path}
              selected={location.pathname === item.path}  // Aplica o estilo quando a rota é selecionada
              sx={{
                '&.Mui-selected': {
                  backgroundColor: '#0095da',
                  color: 'white',
                  '& .MuiListItemText-primary': {
                    fontWeight: 'bold',
                  },
                },
              }}
            >
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
      </DrawerStyled>
      <Content>
        <ToolbarSpacer />
        <div style={{ width: '100%' }}>
          {children}
        </div>
      </Content>
    </Root>
  );
};

export default Layout;