import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/system";
import arrowLeft from "../assets/icons/arrow-left.svg";

const UploadContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginBottom: "1rem",
});

const GoBack = styled("div")({
  width: "100%",
  display: "flex",
  alignItems: "center",
  marginBottom: "1rem",
  justifyContent: "flex-start",
});

const UploadForm = ({
  isSubmitting,
  onFileSelect,
  selectedFile,
  handleGoBack,
}) => {
  return (
    <UploadContainer>
      {!isSubmitting && (
        <GoBack>
          <Box
            sx={{
              cursor: "pointer",
            }}
            display="flex"
            onClick={handleGoBack}
          >
            <img src={arrowLeft} alt="" />
            <Typography variant="body1" style={{ marginLeft: "0.5rem" }}>
              Voltar
            </Typography>
          </Box>
        </GoBack>
      )}
      <Button variant="contained" component="label">
        Escolher Outro Arquivo
        <input type="file" accept=".txt" hidden onChange={onFileSelect} />
      </Button>
      {selectedFile && (
        <Typography variant="body1" style={{ marginTop: "1rem" }}>
          Arquivo selecionado: {selectedFile.name}
        </Typography>
      )}
    </UploadContainer>
  );
};

export default UploadForm;
