export const HttpClient = {
  async post(url, body) {
    const response = await fetch(url, {
      method: "POST",
      body: body,
    });

    return response;
  },

  async get(url) {
    const response = await fetch(url);

    return response;
  },
}

export default HttpClient;