import React, { useEffect, useState, useCallback } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import './styles/App.css';
import Layout from './components/Layout';
import HomePage from './pages/HomePage';
import PathsConfig from './pages/PathsConfig';
import AliceCodigo from './pages/AliceCodigo';
import AliceVulnerabilidades from './pages/AliceVulnerabilidades';
import AliceRequisitos from './pages/AliceRequisitos';
import AliceCOBOL from './pages/AliceCOBOL';
import { cognitoConfig } from './config/cognitoConfig';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true); // Estado para carregamento
  const [error, setError] = useState(null); // Estado para erros

  useEffect(() => {
    // Log para verificar as variáveis de ambiente
    console.log('Ambiente:', process.env.NODE_ENV);
    console.log('Cognito Config:', cognitoConfig);

    // Verifica a autenticação ao carregar a aplicação
    fetch(`${cognitoConfig.redirectUri.replace('/callback', '/check')}`, {
      method: 'GET',
      credentials: 'include', // Inclui cookies na solicitação
    })
      .then(response => {
        if (response.ok) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('Erro na autenticação:', error);
        setError(error.message); // Define a mensagem de erro
        setLoading(false);
      });
  }, []);

  const handleLogin = useCallback(() => {
    const loginUrl = `https://${cognitoConfig.domain}/login?client_id=${cognitoConfig.clientId}&response_type=code&scope=openid+profile+email&redirect_uri=${cognitoConfig.redirectUri}`;
    window.location.href = loginUrl;
  }, []);

  const handleLogout = useCallback(() => {
    fetch(`${cognitoConfig.redirectUri.replace('/callback', '/logout')}`, {
      method: 'POST',
      credentials: 'include', // Inclui cookies na solicitação
    })
      .then(response => {
        if (response.ok) {
          setIsAuthenticated(false);
          window.location.href = cognitoConfig.logoutUri; // Redireciona para a página inicial após logout
        } else {
          throw new Error('Erro ao fazer logout');
        }
      })
      .catch(error => {
        console.error('Erro ao fazer logout:', error);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Exibe uma mensagem de carregamento
  }

  if (error) {
    return <div>Error: {error}</div>; // Exibe uma mensagem de erro
  }

  return (
    <Layout handleLogin={handleLogin} handleLogout={handleLogout} isAuthenticated={isAuthenticated}>
      <Routes>
        <Route path="/" element={<HomePage isAuthenticated={isAuthenticated} handleLogin={handleLogin} />} />
        <Route path="/configuracao-de-paths" element={isAuthenticated ? <PathsConfig /> : <Navigate to="/" />} />
        <Route path="/alice-codigo" element={isAuthenticated ? <AliceCodigo /> : <Navigate to="/" />} />
        <Route path="/alice-vulnerabilidades" element={isAuthenticated ? <AliceVulnerabilidades /> : <Navigate to="/" />} />
        <Route path="/alice-requisitos" element={isAuthenticated ? <AliceRequisitos /> : <Navigate to="/" />} />
        <Route path="/alice-cobol" element={isAuthenticated ? <AliceCOBOL /> : <Navigate to="/" />} />
      </Routes>
    </Layout>
  );
}

export default App;
