// IAResponse.js
import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { ContentCopy as CopyIcon } from '@mui/icons-material';

const IAResponse = ({ text }) => {
  // Função para lidar com a cópia do código
  const handleCopy = (code) => {
    navigator.clipboard.writeText(code);
    alert("Código copiado!");
  };

  // Função para renderizar o texto com formatação adequada
  const renderContent = (text) => {
    if (!text) return null; // Retorna null se o texto não for válido
    
    // Divide o texto em blocos de código e partes de texto comum
    const parts = text.split(/(```[\s\S]*?```)/g);

    return parts.map((part, index) => {
      if (part.startsWith("```")) {
        // Bloco de código
        const code = part.replace(/```/g, '');
        return (
          <Box
            key={index}
            sx={{
              backgroundColor: '#2E3B4E', // Fundo escuro para blocos de código
              color: '#FFFFFF', // Fonte clara
              padding: '1rem',
              borderRadius: '5px',
              position: 'relative',
              marginBottom: '1rem',
              fontFamily: 'monospace',
              width: '100%', // Ocupa toda a largura disponível do chat
              display: 'block', // Garante que o bloco de código ocupe toda a largura
              alignItems: 'center',
              boxSizing: 'border-box', // Garante que o padding seja incluído na largura total
            }}
          >
            <pre style={{ margin: 0, whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>{code}</pre> {/* Remove margens internas de <pre> e ajusta a quebra de linha */}
            <IconButton
              onClick={() => handleCopy(code)}
              sx={{ position: 'absolute', top: '10px', right: '10px', color: '#FFFFFF' }}
            >
              <CopyIcon />
            </IconButton>
          </Box>
        );
      } else {
        // Texto comum com formatação
        const lines = part.split('\n').filter(line => line.trim() !== '');

        return lines.map((line, idx) => {
          // Identifica e formata listas numeradas
          if (/^\d+\.\s/.test(line)) {
            return (
              <Typography key={idx} variant="body1" paragraph sx={{ marginLeft: '1rem', textIndent: '-1rem', width: '100%' }}>
                {line}
              </Typography>
            );
          }

          // Identifica e formata código inline com crase `
          const inlineCode = line.split(/(`.*?`)/g).map((part, id) =>
            part.startsWith('`') && part.endsWith('`') ? (
              <Box component="span" key={id} sx={{ backgroundColor: '#f5f5f5', color: '#d32f2f', padding: '0.1rem', borderRadius: '3px', fontFamily: 'monospace' }}>
                {part.slice(1, -1)}
              </Box>
            ) : (
              part
            )
          );

          return (
            <Typography key={idx} variant="body1" paragraph sx={{ width: '100%' }}>
              {inlineCode}
            </Typography>
          );
        });
      }
    });
  };

  return <div style={{ width: '100%' }}>{renderContent(text)}</div>; // Garante que o container principal ocupa toda a largura disponível
};

export default IAResponse;
