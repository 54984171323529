import { HttpClient } from '../../config/httpClient'

export const convertCsp = async (file) => {
  const formData = new FormData();
  formData.append("file", file);

  const response = await HttpClient.post("/api/v1/csp-cobol-convert", formData);
  return response
}

export const convertBms = async (file) => {
  const formData = new FormData();
  formData.append("file", file);

  const response = await HttpClient.post("/api/v1/mapa-bms-convert", formData);
  return response
}